import React from 'react';
import styled from 'styled-components';
import { Text, TextVariant } from '@naf/text';
import { Link } from 'react-router-dom';
import { breakpoints, spacing } from '@naf/theme';
import { ButtonLink } from '@naf/button-link';
import { NumberInCircle } from '@naf/number';
import { useDocumentUrlWithNode } from '../../../hooks/useDocumentUrl';
import { ImageTitleWithLinksType, ListType } from '../../../../../types/ImageTitleWithLinks';
import ResponsiveImage from '../../image/ResponsiveImage';

type Props = {
  block: ImageTitleWithLinksType;
};

const ImageTitleAndLinkList = ({ block }: Props) => (
  <Wrapper>
    <HeaderWrapper>
      <MainTitle variant={TextVariant.Header1}>{block.title}</MainTitle>
      <StyledImage imageId={block.image?.publicId} altText={block.image?.altText} />
    </HeaderWrapper>
    <ListWrapper>
      <DottedLine />
      {block.linkListWithTitleAndIngress.map((item, index) => (
        <LinkListItem item={item} number={index + 1} key={item.title} />
      ))}
    </ListWrapper>
  </Wrapper>
);

const LinkListItem = ({ item, number }: { item: ListType; number: number }) => {
  const internalLink = useDocumentUrlWithNode(item.internalLink);
  return (
    <ItemWrapper>
      <Number count={number} />
      <StyledTitle variant={TextVariant.Header3}>{item.title}</StyledTitle>
      {item.ingress && <ListIngress variant={TextVariant.ArticleText}>{item.ingress}</ListIngress>}
      {internalLink && <ButtonLink as={Link} to={internalLink} text="Les mer" />}
      {item.externalLink && (
        <ButtonLink href={item.externalLink?.href} target="_blank" rel="noreferrer" text="Les mer" />
      )}
    </ItemWrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;

const MainTitle = styled(Text)`
  margin-bottom: 0;
  margin-top: ${spacing.space32};
`;

const ListWrapper = styled.ul`
  position: relative;
  height: fit-content;
  display: flex;
  row-gap: ${spacing.space80};
  column-gap: ${spacing.space32};
  flex-wrap: wrap;
  list-style-type: none;
  margin-top: ${spacing.space80};
  padding: 0;
`;

const ItemWrapper = styled.li`
  display: flex;
  flex-direction: column;
  gap: ${spacing.space16};
  width: 352px;

  @media (max-width: ${breakpoints.m}) {
    margin-left: ${spacing.space32};
  }

  @media (max-width: ${breakpoints.xl}) {
    flex-grow: 1;
  }
`;

const StyledTitle = styled(Text)`
  margin: 0;
`;
const ListIngress = styled(Text)`
  margin: 0;
  height: 72px;
  overflow: hidden;
  line-height: 1.5rem;

  @media (max-width: ${breakpoints.m}) {
    height: 100%;
  }
`;

const Number = styled(NumberInCircle)`
  @media (max-width: ${breakpoints.m}) {
    background-color: white;
    position: absolute;
    left: 0;
    width: 32px;
    height: 32px;
    margin-left: -16px;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
`;

const StyledImage = styled(ResponsiveImage)`
  position: relative;
  padding: ${spacing.space56};
  width: 389px;
  height: auto;
  @media (max-width: ${breakpoints.m}) {
    display: none;
  }

  & > img {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
  }
`;

const DottedLine = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-left: 2px dotted;
  padding-left: 0;
  z-index: -1;

  @media (min-width: ${breakpoints.m}) {
    display: none;
  }
`;

export default ImageTitleAndLinkList;
